import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FactCard from './FactCard';

const styles = (theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
});

const FactCards = (props) => {
    const { classes, data = [] } = props;

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                {data && data.map((id) => <FactCard key={id} id={id} />)}
            </div>
        </div>
    );
};

export default withStyles(styles)(FactCards);
