import React from 'react';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { GQL_FETCH_FACTCARD } from '../graphql';
import { withStyles } from '@material-ui/core/styles';
import { Loader } from '@manakin/app-core';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const styles = (theme) => ({
    root: {
        width: '30rem',
        padding: '4rem 2rem',
        backgroundColor: theme.manakin.secondaryColor[500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 15px 30px',
        textDecoration: 'none',
    },
    heading: {
        color: 'white',
    },
    content: {
        color: 'white',
        margin: 0,
        fontSize: '1.7rem',
    },
});

const FactCard = (props) => {
    const { classes, data = {}, id } = props;
    const { loading = true, factCard = {} } = data;

    if (loading) {
        return <Loader fullScreen={true} />;
    }
    return (
        <Link to={`/factCard/${id}`} className={classes.root}>
            <div>
                <Typography variant="h5" className={classes.heading}>
                    {factCard.title || ''}
                </Typography>
                <Typography variant="body1" className={classes.content}>
                    {factCard.text || ''}
                </Typography>
            </div>
        </Link>
    );
};

export default compose(
    graphql(GQL_FETCH_FACTCARD, {
        options: (props) => ({ variables: { id: props.id } }),
    }),
    withStyles(styles)
)(FactCard);
