export const styles = (theme) => ({
    smallWrapper: {
        ...theme.manakin.smallWrapper,
        maxWidth: '75rem',
        paddingTop: '5rem',
    },
    wrapper: {
        ...theme.manakin.smallWrapper,
    },
    contentColor: {
        color: theme.manakin.primaryColor[500],
    },
    content: {
        paddingBottom: '10rem',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '5rem',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '7.5rem',
            paddingBottom: '12rem',
        },
    },
    subTitle: {
        '& b': {
            color: theme.manakin.secondaryColor[500],
            display: 'block',
            [theme.breakpoints.up('md')]: {
                display: 'inline',
                marginRight: '2.4rem',
            },
        },
    },
    alarm: {
        width: '6rem',
        height: '6rem',
        backgroundColor: theme.manakin.secondaryColor[500],
        position: 'absolute',
        top: '6rem',
        right: '-5rem',
        color: 'white',
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    innerContent: {
        '&>*': {
            color: theme.manakin.primaryColor[500],
        },
        '& p': {
            marginBottom: '2rem',
            color: theme.manakin.primaryColor[500],
            [theme.breakpoints.up('lg')]: {
                marginBottom: '3rem',
            },
        },
        '& h2': {
            color: theme.manakin.primaryColor[500],
            fontSize: '2rem',
            lineHeight: '3.2rem',
            fontWeight: 'bold',
            marginBottom: '2rem',
        },
    },
    heading: {
        [theme.breakpoints.up('md')]: {
            fontSize: '5.6rem',
            lineHeight: '7rem',
        },
    },
    headingImage: {
        width: '100%',
        height: '20rem',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            height: '44rem',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '5rem 0px 2rem',
        },
    },
    marginBottom: {
        marginBottom: '2rem',
        [theme.breakpoints.up('lg')]: {
            marginBottom: '5rem',
        },
    },
    newsItems: {
        backgroundColor: theme.manakin.secondaryBackgroundColor[500],
        paddingBottom: '10rem',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '0',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '5rem',
        },
    },
    factCards: {
        marginTop: '9rem',
    },
});
