import gql from 'graphql-tag';
import { appAssetWithThumbnail } from '@manakin/app-core/graphQlHelpers';

export const GQL_FETCH_NEWS = gql`
    query($id: ID!) {
        news(id: $id) {
            id
            title
            introText
            category
            factCards
            content
            image {
                ${appAssetWithThumbnail}
            }
            lastModifiedOn
            createdOn
        }
    }
`;

export const GQL_FETCH_FACTCARD = gql`
    query($id: ID!) {
        factCard(id: $id) {
            id
            title
            text
        }
    }
`;
