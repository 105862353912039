import React, { useEffect } from 'react';
import { ElementOverlay, LoadImage } from '@manakin/app-core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { styles } from './styles';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { GQL_FETCH_NEWS } from './graphql';
import { Loader } from '@manakin/app-core';
import renderHTML from 'react-render-html';
import classNames from 'classnames';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FactCards from './parts/FactCards';
import moment from 'moment';
import 'moment/min/locales';
import { useTranslation } from 'react-i18next';

const NewsContainer = (props) => {
    const { classes, data = {} } = props;
    const { news = {}, loading = true } = data;
    const { t } = useTranslation();

    const time = moment(news.createdOn, ['DD-MM-YYYY', moment.ISO_8601])
        .locale(t("common.locale-code"))
        .calendar();

    //functions
    const handleClick = () => {
        props.history.push('/');
    };

    //effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading) {
        return (
            <div>
                <Loader fullScreen={true} />
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <ElementOverlay
                controls={false}
                customControls={true}
                onClick={handleClick}
            >
                <div className={classes.content}>
                    <div className={classes.smallWrapper}>
                        <Typography variant="h1" className={classes.heading}>
                            {news.title || ''}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classNames(
                                classes.contentColor,
                                classes.subTitle
                            )}
                        >
                            {news.category &&
                                news.category.includes('URGENT') && (
                                    <b>{t("app.news.urgent")}</b>
                                )}
                            {time && t("app.news.placed-at", { time: time })}
                        </Typography>
                        {news.category && news.category.includes('URGENT') && (
                            <div className={classes.alarm}>
                                <ErrorIcon />
                            </div>
                        )}
                    </div>
                    {news.image && news.image.url && (
                        <div className={classes.wrapper}>
                            <div className={classes.headingImage}>
                                <LoadImage
                                    src={news.image.url}
                                    placeholder={
                                        news.image.thumbnailUrl ||
                                        news.image.url
                                    }
                                />
                            </div>
                        </div>
                    )}
                    <div className={classes.smallWrapper}>
                        <Typography
                            variant="body1"
                            component="div"
                            className={classes.innerContent}
                        >
                            {news.introText && (
                                <h5 className={classes.marginBottom}>
                                    {news.introText}
                                </h5>
                            )}
                            {renderHTML(news.content || '')}
                        </Typography>
                        {news.factCards && news.factCards.length > 0 && (
                            <div className={classes.factCards}>
                                <FactCards data={news.factCards} />
                            </div>
                        )}
                    </div>
                </div>
            </ElementOverlay>
            {/* <div className={classes.newsItems}>
        <NewsCarousel />
      </div> */}
        </div>
    );
};

export default compose(
    withStyles(styles),
    graphql(GQL_FETCH_NEWS, {
        options: (props) => ({
            variables: { id: props.match.params.elementId },
        }),
    })
)(NewsContainer);
